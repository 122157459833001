export default {
  datePicker: {
    selectDate: "Select date",
    selectStart: "Select start date",
    selectEnd: "Select end date",
  },
  tankMixes: {
    title: "Crop protection tank mixes",
    createNew: "Create new",
    createEdit: "Create or modify tank mix",
    editMix: "Edit tank mix",
    name: "Tank mix name",
    type: "Tank mix type",
    save: "Save tank mix",
    newMix: "New mix",
    predefinedMix: "Predefined mix",
    emptyProduct: "Empty product",
    noTankMixes: "Nothing to see here",
    selectTankMix: "Select a tank mix",
    selectPredefinedMix: "Choose pre-defined mix",
    amount: "Amount",
    product: "Product",
  },
  pagination: { showing: "Showing", of: "of", showMore: "Show more" },
  addCrop: {
    noSurfaceArea: "Specify surface area for location in settings",
    totals: "Totals",
    amount: "Amount",
    surfaceArea: "Surface area",
    amountsAndSurfaceArea: "Amounts and surface area",
    cropsPerSquareMeter: "crops / m²",
    squareMetersPerCrop: "m² / crop",
    percentageOfLocation: "% of location",
    totalSurfaceArea: "Total surface area at location",
    plantsAtLocation: "Plants at location",
    numberOfContainers: "Number of containers",
    plantsPerContainer: "Plants per container",
    objectives: "Objectives and challenges for this crop",
    spacingDate: "Arrival date of young plant material",
    addSubstrateInfo: "Add substrate info",
    selectClassification: "Select classification",
    profile: "Crop profile",
    statusActive: "Active",
    labId: "Lab ID",
    potSize: "Pot size",
    tray: "Tray",
    openField: "Open field",
    currentAmount: "Current amount",
    substrateUniqueKey: "Substrate unique key",
    noSubstrateUniqueKey: "No substrate unique key",
    recipe: "Recipe",
    targetPh: "Target pH",
    targetEc: "Target EC",
    nameDetails: "Crop name details",
    genericName: "Genus",
    selectOption: "Select option",
    specificEpithet: "Species",
    cultivar: "Cultivar",
    chooseYourName: "Choose your own name…",
    typeDetails: "Crop type details",
    container: "Container",
    selectOptionOrCreate: "Select option or create new",
    numberOfCellsPerTray: "Number of cells per tray",
    numberOfTrays: "Number of trays",
    plugType: "Plug type",
    growcoon: "Growcoon",
    locationBusiness: "Business location",
    locationsLevel1: "Locations level 1",
    locationsLevel2: "Locations level 2",
    referenceGenus: "Reference genus",
    phase: "Crop phase",
    startNumber: "Start number",
    currentNumber: "Current number",
    drippers: "Drippers",
    youngPlantSuppliers: "Young plant suppliers",
    labels: "Crop labels",
    sizeDescription: "Size description",
    supplierDocumentNumber: "Supplier document number",
    containerNumber: "Container number",
    substrateMixture: "Substrate mixture",
    fertilization: "Fertilization",
    labDataId: "Lab id",
    barcode: "Barcode",
    batchNumber: "Batch number",
    bulbSize: { title: "Bulb size" },
    description: "Description",
    goals: "Goals",
    timeDetails: "Crop time",
    weekInYear: "Week number",
    dateOfPotting: "Pot date",
    endDate: "End date",
    expectedCultivationTime: "Expected cultivation time (in weeks)",
    pot: "Pot",
    generative: "Generative",
    vegetative: "Vegetative",
    youngPlantSupplier: "Young plant supplier",
    labelName: "Label name",
    protocol: "Protocol",
    errors: {
      genusRequired: "Genus is required",
      potSizeRequired: "Pot size is required",
      trayRequired: "Tray is required",
      cellsPerTrayMin: "Number of cells per tray can not be less than 1",
      traysMin: "Number of trays can not be less than 1",
      plugTypeRequired: "Plug type is required",
      weekNumberRequired: "Week number is required",
      pottingStartDateRequired: "Potting start date is required",
      endDateRequired: "End date is required",
      labDataIdUnique: "Lab data id must be unique for each crop",
    },
    plugTypeOptions: {
      loose: "Loose filled trays",
      growcoon: "Growcoon",
      paper: "Paper Pot",
      glued: "Glued plugs",
      rockwool: "Rockwool",
      oasis: "Oasis",
    },
    cultivationTrial: "Cultivation trial",
    mainCrop: "Main crop",
    edit: "Edit crop",
    add: "Add crop",
    saveChanges: "Save changes",
    saveNew: "Save new crop",
    deleteCrop: "Delete crop",
    createCrop: "Create crop",
    businessLocation: "Business location",
    cropLabel: "Crop label",
    notOpenField: "Not open field",
    productionDate: "Delivery date",
  },
  cropCard: {
    started: "Started",
    expected: "Expected",
    finished: "Finished",
    showDetails: "Show details",
    editCropProfile: "Edit crop profile",
    week: "Week",
    cultivationWeek: "Cultivation week",
    calendarWeek: "Calendar week",
    logHistory: "Log history",
    labAnalyses: "Lab analyses",
    protocol: "Protocol",
    advice: "Advice",
    cropProtection: "Crop protection",
    documents: "Documents",
  },
  exportCultivationData: {
    title: "Export cultivation data",
    description:
      "Export your cultivation data as a file to share your progress with other growers.",
    notAvailable: "No entries matching selected filters",
    exportType: {
      cultivation: "Cultivation",
      fertilization: "Fertilization",
      cropProtection: "Crop protection",
      fertilizationBins: "Fertilization tanks",
    },
    entries: "entries",
    exportRange: "Export range",
  },
  exportLogData: {
    columns: {
      tankage: "Tankage",
      calendarWeek: "Calendar week",
      calendarYear: "Calendar year",
      cultivationWeek: "Cultivation week",
      temperatureC: "Temperature (°C)",
      temperatureF: "Temperature (°F)",
      temperatureCMin: "Temperature min (°C)",
      temperatureCMax: "Temperature max (°C)",
      humidityPercent: "Humidity (%)",
      precipitation: "Precipitation",
      irradiation: "Irradiation",
      leafWetness: "Leaf wetness",
      date: "Date",
      note: "Note",
      failureUnitsByLocation: "Failure units by location",
      deliveredUnitsByLocation: "Delivered units by location",
      locationChange: "Location change",
      labData: "Lab data",
      activity: "Activity",
      climateDataLocations: "Climate data locations",
      climateData: "Climate data",
      fertilizationBinLocations: "Fertilization bin locations",
      fertilizationBin: "Fertilization bin",
      failureUnits: "Failure units",
      failureRate: "Failure rate (%)",
      cause: "Cause",
      ratingSubject: "Rating subject",
      observationValue: "Observation value",
      observationCount: "Observation count",
      observationRating: "Observation rating",
      products: "Products",
      actionUnits: "Action units",
      actionValue: "Action value",
      wateringAmount: "Watering amount",
      wateringEc: "EC during watering",
      measurementUnits: "Measurement units",
      measurementSourceType: "Measurement source type",
      measurementValue: "Measurement value",
      user: "User",
      photos: "Photos",
      photoLabel: "Photo label",
      labels: "Labels",
    },
  },
  labAnalysis: {
    title: "Lab analysis",
    description: "All your lab analyses data in one place.",
    benchmarking: {
      description: `Benchmarking is done based on the target values of the ‘Bemestings
      Advies Basis’ (Dutch advice system for fertilisation)`,
      title: "Key",
      tooHigh: "Too high",
      high: "High",
      low: "Low",
      tooLow: "Too low",
    },
    dateSamplingUnit: "Date sampling unit",
    descriptionAndNotes: "Description and notes",
    sampleDescription: "Sample description",
    saveAnalysis: "Save lab analyses report",
    showLabAnalysis: "Show lab analysis report",
    createAnalysis: "Create lab analyses report",
    chooseType: "Choose type",
    saveNote: "Save note",
    addANoteToSampleOf: "Add a note to sample of",
    noteText: "Note text",
  },
  layout: {
    summary: "Summary",
    exportCultivationData: "Export cultivation data",
    cultivations: "Cultivations",
    closeSidebar: "Close sidebar",
    settings: "Settings",
    faq: "FAQ",
    contact: "Contact",
    disclaimer: "Disclaimer",
    openSidebar: "Open sidebar",
    photoComparison: "Photo comparison",
    viewMyAccount: "View my profile",
    companyAccount: "Account",
    account: "Company account",
    switchCompany: "Switch company",
    signOut: "Sign out",
    dashboard: "Dashboard",
    sensors: "Sensors",
    myTasks: "My tasks",
    protocols: "Protocols",
    advices: "Advices",
    documents: "Documents",
    addCrop: "Add crop",
    addNote: "Add note",
    companySettings: "Company settings",
    locationSettings: "Location settings",
    sensorSettings: "Sensor settings",
    userManagement: "User management",
    lsSettings: "Log & Solve settings",
    appSettings: "App settings",
    createCropsSettings: "Create crops settings",
    exportSettings: "Export settings",
    cultivationActivities: "Cultivation activities",
    fertilization: "Fertilization",
    climateData: "Climate data",
    protectionTankMixes: "Protection tank mixes",
    notesAndChecklists: "Notes & checklists",
    substrates: "Substrates",
    climateBoxes: "Climate boxes",
    importCultivationData: "Import crops",
    labAnalisis: "Lab analysis",
  },
  add: {
    newCrop: "New crop",
    newNote: "New note",
    newProtocol: "New protocol",
    newTask: "New task",
  },
  alerts: {
    markAllAsSeen: "Mark all as seen",
    mentionedYou: "mentioned you",
    addedANewNote: "added a new note",
    addedANewAdvice: "added a new advice",
    commentedOnLabData: "commented on lab data",
    newPendingLabData: "New pending lab data",
    newLabData: "New lab data",
    newNotifications: "New notifications",
    olderNotifications: "Older notifications",
  },
  summary: {
    title: "Grower’s summary & insights",
    description:
      "Effortlessly navigate and optimize your cultivation strategy for informed decision-making",
    gotoSummary: "Go to summary",
    summary: "Summary",
    insights: "Insights",
    branch: {
      withPhoto: "With photo",
      labData: "Lab data",
      advice: "Advice",
      document: "Document",
      fertilizationBin: "Fertilization tanks",
      climateData: "Climate data",
    },
    lastWeek: "Last week",
    lastTwoWeeks: "Last two weeks",
    lastMonth: "Last month",
    custom: "Custom",
    addTaskToSelected: "Add task to selected",
    addAdviceToSelected: "Add advice to selected",
  },
  labData: {
    pending: "Pending lab analysis",
    completed: "Completed lab analysis",
  },
  upload: {
    progress: "Progress",
    speed: "Speed",
    timeLeft: "Time left",
    seconds: "s",
  },
  agreement: {
    weValueYourPrivacy: "We value your privacy",
    dataSafety:
      "Your data is safe with us. All data is stored on AVG compliant servers. We do not sell your data to external parties. We do not store data that we do not need. But we would like to keep improving our software services. Want to know more? Read the privacy statement or send us an email.",
    read: "Read terms and conditions",
  },
  common: {
    mention: "Mention",
    new: "New",
    prefillData: "Prefill data",
    cropRequired: "Crop is required",
    dateRequired: "Date is required",
    noDataToDisplay: "No data to display",
    no: "No",
    exportType: "Export type",
    fileType: "File type",
    preview: "Preview",
    done: "Done",
    reset: "Reset",
    resetFilters: "Reset filters",
    updated: "Updated",
    export: "Export",
    exportCSV: "Export as CSV",
    exportXLSX: "Export as XLSX",
    name: "Name",
    apply: "Apply",
    pleaseFillAllFields: "Please fill all the required fields",
    deselect: "Deselect",
    duplicate: "Duplicate",
    nothingFound: "Nothing found",
    save: "Save",
    saveAddNew: "Save + add new",
    remove: "Remove",
    of: "of",
    next: "Next",
    back: "Back",
    loading: "Loading",
    edit: "Edit",
    yesterday: "Yesterday",
    today: "Today",
    more: "More",
    download: "Download",
    title: "Title",
    description: "Description",
    owner: "Owner",
    lastModified: "Last modified",
    viewAll: "View all",
    open: "Open",
    create: "Create",
    select: "Select",
    date: "Date",
    saveChanges: "Save changes",
    fieldIsRequired: "Field is required",
    code: "Code",
    label: "Label",
    cancel: "Cancel",
    update: "Update",
    complete: "Complete",
    action: "Action",
    created: "Created",
    upload: "Upload",
    forUsers: "For users",
    saveAndRepeat: "Save + repeat",
    saveAndNew: "Save + new",
    skipAndSaveAndNew: "Skip + save + new",
    skipAndSaveAndRepeat: "Skip + save + repeat",
    active: "Active",
    inactive: "Inactive",
    companies: "Companies",
    isARequiredField: "is a required field",
    clickToUpload: "Click to upload",
    orDragAndDrop: "or drag and drop",
    added: "Added",
    settings: "Settings",
    backToHome: "Back to home",
    includeInactive: "Include inactive",
    start: "Start",
    end: "End",
    weeks: "Weeks",
    typeToSearch: "Type to search",
  },

  advices: {
    createYourFirstAdvice: "Create your first advice",
    greatWay: "Advices are a great way to share your knowledge with your team",
    openAdvices: "Open advices",
    newAdvice: "New advice",
    createAdvice: "Create advice",
    editAdvice: "Edit advice",
    title: "Advices",
    preview: "Advice preview",
  },
  documents: {
    createYourFirstDocument: "Create your first document",
    greatWay:
      "Documents are a great way to share your knowledge with your team",
    openDocuments: "Open documents",
  },
  dashboard: {
    components: {
      ph: { title: "pH value" },
      ec: { title: "EC value" },
      composition: { title: "Composition" },
      ratio: { title: "Ratio" },
      analysis: {
        overview: "Overview results analysis",
        labDataTable: { addNote: "Add note" },
      },
      uploadPhoto: {
        options: {
          overview: { value: "Overview" },
          single: { value: "Single plant" },
          detail: { value: "Detail" },
          roots: { value: "Roots" },
        },
      },
    },
    dashboard: "Dashboard",
    dashboardCaption: "An aggregated view of all your data.",
    cultivations: "Cultivation overview",
    cultivationsCaption: "An aggregated view of all your cultivation data.",
    loveYourFeedback: "We’d love your feedback",
    latestActivity: "Latest activity",
    protocols: "Protocols",
    recentTasks: "Recent tasks",
  },
  fertilizationBins: {
    amount: "Amount",
    bin: "Tank",
    concentration: "Concentration",
    dateEnd: "End date",
    dateStart: "Start date",
    locations: "Locations",
    name: "Tank name",
    product: "Product",
    searchForProduct: "Search for fertilizer",
    settings: {
      createEdit: "Create or modify fertilization bin",
      createNew: "Create new",
      manage: "Manage settings",
      noBins: "No fertilization tanks",
      selectLocations: "Select locations",
      settingsUpdated: "Settings have been updated",
      title: "Settings",
    },
    title: "Fertilization",
    type: "Tank type",
    unit: "Unit",
    wizard: {
      manage: "Manage entries",
      pickBin: "Pick a tank",
      subtitle: "Create and manage your cultivation’s fertilization",
      title: "Register new",
    },
  },
  pendingLabAnalysis: { dateSamplingUnit: "date sampling unit" },
  cropsImport: {
    title: "Import crops",
    menu: { wizard: "Import wizard", settings: "Import settings" },
    inputFormats: {
      voorbeeld: "Wintree TSD",
      wouters: "Mprise agriware",
      voorraadpaartij: "GroenVision",
    },
    processFile: "Process file",
    saveCrops: "Save crops",
    progress: {
      created: "Created",
      updated: "Updated",
      skipped: "Skipped",
      failed: "Failed",
    },
    settings: {
      familyCodes: "Family codes",
      familyCode: "Family code",
      locationCodes: "Location codes",
      locationCode: "Location code",
      potsizeCodes: "Potsize codes",
      trayCodes: "Tray codes",
      growcoonCodes: "Growcoon codes",
      plugTypeCodes: "Plug type codes",
      fieldsMaps: "Fields maps",
      dedicatedLocCols: "Dedicated location columns",
      save: "Save",
      settingsUpdated: "Crops import settings updated",
    },
    table: {
      imported: {
        heading: "Imported from file.",
        description:
          "Review your crops data. Click on a row to edit the crop info.",
      },
      failed: {
        heading: "Invalid crop data.",
        description:
          "Something is not right. Please review upcoming data. Please check these crops first before you can proceed with importing. Edit the crop or don't include the crop.",
      },
    },
    include: "Include",
  },
  protocol: {
    title: {
      protocolKPIs: "Protocol KPI's:",
      protocolPlanning: "Protocol planning",
      week: "Week",
      createYourFirst: "Create your first protocol",
      developStrategy:
        "Develop a crop strategy to share and aligning with your team",
      startCreating: "Start creating your protocol",
      or: "or",
      applyExisting: "Apply from existing",
      duplicateProtocol: "Duplicate protocol",
      editProtocol: "Edit protocol",
      addProtocol: "Add protocol",
      protocolName: "Protocol:",
      stage: "Stage:",
      weeks: "Weeks",
      number: "Number",
      withoutStage: "Without stage",
      tank: "Tank",
      myProtocols: "Protocols",
      combinedProtocolsPlanning: "Combined protocols planning",
      separateProtocolsPlanning: "Separate protocols planning",
      allInOne: "All your protocols in one view.",
      observations: "Protocol Observations:",
      createAndManage: "Create and manage your cultivation’s protocols.",
      new: "New protocol",
      plantingStartDates: "Planting start dates",
      plantingEndDates: "Planting end dates",
      any: "Any",
      protocol: "Protocol",
      taxonomy: "Taxonomy",
      container: "Container",
      plantingDates: "Planting dates",
      tanksAndDescriptions: "Tanks and descriptions",
      weatherAndDescription: "Weather and description",
      potSizes: "Pot Sizes:",
      startDate: "Start date:",
      kpis: "Kpi's:",
      action: "Action",
      alert: "Alert",
    },
    add: {
      label: {
        checklistItem: "Checklist",
        amount: "Amount",
        units: "Units",
        min: "Min",
        max: "Max",
        genus: "Genus",
        species: "Species",
        cultivar: "Cultivar",
        potSize: "Pot size",
        tray: "Tray",
        checklist: "Select checklist",
        openField: "Open field",
        startMonth: "Start month",
        startDay: "Start day",
        endMonth: "End month",
        endDay: "End day",
        name: "Name",
        from: "From",
        until: "Until",
        assignToUsersByDefault: "Assign all actions to users by default",
        assignToSpecificUsers: "Assign to specific users",
        users: "Users",
        description: "Description",
        week: "Week",
        recurringAction: "Recurring",
        repeats: "Repeats count",
        repeatWeekOffset: "Repeat week offset",
        active: "Active",
        failureAmount: "Failure amount",
        option: "Option",
        secondOption: "Second option",
        value: "Value",
        fromWeek: "From week",
        untilWeek: "Until week",
        product: "Product",
        parameter: "Parameter",
        timeframe: "Timeframe",
        pot: "Pot",
        conditionType: "Condition type",
        values: "Values",
        climateSchema: "Climate schema",
      },
      stage: {
        type: "Type",
        conditions: "Cultivation conditions",
        climateSchema: "Climate schema",
        kpis: "KPI's",
        stages: "Stages",
        actions: "Actions",
        alerts: "Alerts",
        fertilizationSchematics: "Fertilization schematics",
        generalConditions: "General cultivation conditions",
      },
    },
    button: {
      addStartDates: "Add start dates",
      addKpi: "Add KPI",
      addStage: "Add stage",
      addActionTo: "Add action to",
      addActionWithoutStage: "Add action without stage",
      addAlertTo: "Add alert to",
      addAlertWithoutStage: "Add alert without stage",
      addTank: "Add tank",
      addIngredient: "Add ingredient",
      addFertilizationSchema: "Add fertilization schema",
      addWeatherTarget: "Add weather target",
      addClimateSchema: "Add climate schema",
    },
    condition: {
      potting_planting: "Potting/Planting",
      substrate: "Substrate",
      starting_material: "Starting material",
      fertilization: "Fertilization",
      rooting: "Rooting",
      irrigation_water_ec: "Irrigation water EC",
    },
  },
  task: {
    type: {
      name: {
        pruning: "Pruning",
        pinching: "Pinching",
        spacing: "Spacing",
        widening: "Spacing",
        move: "Move",
        sorting: "Sorting",
        changePotsize: "Change container",
        rotate: "Rotate",
        cuttingLeaf: "Cutting leaf",
        layFoil: "Lay foil",
        removeFoil: "Remove foil",
        harvest: "Harvesting",
        lower: "Lower",
        settle: "Secure",
        removeLeafs: "Remove leafs",
        bendIn: "Bend in",
        placeSticks: "Place sticks",
        siftingThrough: "Sifting through",
        pollinate: "Pollinate",
        binding: "Binding",
        weeding: "Weeding",
        deflower: "Deflower",
        removeTendrils: "Remove tendrils",
        placeCatchCards: "Place catch cards",
        tendrils: "Tendrils",
        raiseTheGauze: "Raise the gauze",
        cropMaintenance: "Crop maintenance",
        removeBuds: "Remove buds",
        technicalService: "Technical service",
        PPP: "Plant protection product",
        biocide: "Biocide",
        adjuvant: "Adjuvant",
        liquidCropControl: "Liquid crop control",
        spreadingCropControl: "Spreading crop control",
        growthRegulation: "Growth regulation",
        floweringTreatment: "Flowering treatment",
        controlRound: "Control round",
        spreadableFertilizers: "Spreadable fertilizers",
        foliarFertilizers: "Foliar fertilizers",
        scouting: "Scouting",
        misc: "Misc",
        addPhoto: "Add photo",
        budding: "Pinching",
        checklist: "Checklist",
      },
      defaultDescription: {
        pruning: "Prune the batch",
        pinching: "Pinch the batch",
        spacing: "Space the plants",
        move: "Move the batch",
        sorting: "Sort the batch",
        changePotsize: "Change container of batch",
        rotate: "Rotate",
        cuttingLeaf: "Cut the leafs",
        layFoil: "Lay foil",
        removeFoil: "Remove foil",
        harvest: "Harvest the production",
        lower: "Lower",
        settle: "Secure",
        removeLeafs: "Remove leafs",
        bendIn: "Bend in",
        placeSticks: "Place sticks",
        siftingThrough: "Sifting through",
        pollinate: "Pollinate",
        binding: "Binding",
        weeding: "Weeding",
        deflower: "Deflower",
        removeTendrils: "Remove tendrils",
        placeCatchCards: "Place catch cards",
        tendrils: "Tendrils",
        raiseTheGauze: "Raise the gauze",
        cropMaintenance: "Crop maintenance",
        removeBuds: "Remove buds",
        technicalService: "Technical service",
        PPP: "Plant protection product",
        biocide: "Biocide",
        adjuvant: "Adjuvant",
        liquidCropControl: "Spray liquid crop control.",
        spreadingCropControl: "Carry out spreading crop control",
        growthRegulation: "Carry out growth regulation",
        floweringTreatment: "Carry out flowering treatment",
        controlRound:
          "Perform a control round: measurements, photo, scouting observations",
        spreadableFertilizers: "Carry out spreadable fertilization",
        foliarFertilizers: "Carry out foliar fertilizer",
        scouting:
          "Scout for defect phenomena, pests, fungus and bacterial diseases",
        misc: "Miscellaneous",
        checklist: "Checklist",
      },
      label: "Type",
    },
    title: {
      description: "Description",
      details: "Details",
      rescheduleTasks: "Reschedule tasks",
      mentionedUsers: "Mention user(s)",
      crops: "crops",
      assignedTo: "Assigned to",
      notAssigned: "Not assigned",
      taskDetails: "Task details",
      taskType: "Task type",
      deadline: "Deadline",
      createdBy: "Created by",
      protocol: "Protocol",
      done: "Done",
      startDate: "Start date",
      cultivationName: "Cultivation name",
      create: "Create a task",
      edit: "Edit task",
      mytasks: "Tasks",
      efficientlyManage:
        "Efficiently manage tasks and protocol assignments with your team.",
      newTask: "New task",
      selected: "selected",
      perform: "Perform",
      for: "for",
      progress: "Progress",
      status: "Status",
      noProtocol: "No protocol",
      todo: "To do",
      today: "Today",
      myTasks: "My tasks",
      allTasks: "All tasks",
    },
    description: { label: "Description", placeholder: "Description" },
    buttons: {
      showInactiveCrops: "Show inactive crops",
      edit: "Edit task",
      markDone: "Mark as done",
      addNoteToSelected: "Add note to selected",
      markSelectedAsDone: "Mark selected as done",
      deselect: "Deselect",
      delete: "Delete task",
      addNoteToRemaining: "Add note to remaining",
      removeSelected: "Remove selected",
      rescheduleTasks: "Reschedule tasks",
    },
    selectLocation: {
      label: "Select all crops from location",
      placeholder: "Pick locations",
    },
    withOverdue: "With overdue",
    progressInHectares: "Progress in hectares",
    checklist: { label: "Checklist" },
    selectCrop: "Select crop",
    isRecurringTask: "Recurring task",
    repeats: {
      label: "Repeats count",
      help: "The number of times this tasks repeats",
    },
    repeatWeekOffset: {
      label: "Repeat week offset",
      help: "The amount of weeks between repetitions. Pick 1 for weekly repeating tasks, 2 for bi-weekly etc.",
    },
    user: { label: "User" },
  },
  locations: {
    locationLevel1: "Location level 1",
    locationLevel2: "Location level 2",
    businessLocations: "Business locations",
    businessLocation: "Business location",
    manageBusinessLocationSettings: "Manage your business location settings.",
    newLocation: "New location",
    lCaseLocations: "locations",
    config: "Location settings",
    newLevel1: "New level 1 location",
    locationName: "Location name",
    locationAddress: "Location address",
    surfaceArea: "Surface area",
    newLevel2: "New level 2 location",
    level2Locations: "Level 2 locations",
  },
  tableComponent: {
    stickyColumn: "Sticky column",
    showAllRows: "Show all rows",
    search: "Search",
    noteType: "Note type",
    showInactive: "Show inactive",
    icon: "Icon",
    showColumns: "Select columns",
    noColumns: "Select columns to show",
    progress: "Progress",
    alerts: "Alerts",
    reset: "Reset",
    label: "Label",
    date: "Pot Date",
    pottingWeek: "Potting week",
    weekNr: "Week number",
    endDate: "End date",
    potDate: "Pot date",
    potSize: "Pot size",
    tray: "Tray",
    trayCellsAmount: "Number of cells per tray",
    traysAmount: "Number of trays",
    plugType: "Plug type",
    container: "Container",
    growcoon: "Growcoon type",
    openField: "Open field",
    startingNumberOfPots: "Start number",
    currentNumberOfPots: "Current number",
    genus: "Genus",
    genusName: "Genus",
    speciesName: "Species",
    cultivarName: "Cultivar",
    labDataId: "Lab id",
    barcode: "Barcode",
    batchNumber: "Batch nr.",
    youngPlantSuppliers: "Young plant suppliers",
    bulbSize: "Bulb size",
    personPlanted: "Person who planted",
    isTrialCrop: "Show trials only",
    species: "Species",
    cultivar: "Cultivar",
    location: "Location",
    businessLocation: "Business Location",
    locationLevel1: "Location Level 1",
    locationLevel2: "Location Level 2",
    startingDates: "Starting dates",
    sizeDescription: "Size description",
    supplierDocumentNr: "Supplier document nr.",
    containerNr: "Container number",
    updates: "Updates",
    variety: "Variety",
    measure: "Pot",
    sort: "Sort",
    show_location: "Show Location",
    status: "Show Inactive",
    search_placeholder: "Search crop ...",
    select: { all: "All", month: "Month", week: "Week" },
    next: "Next",
    prev: "Previous",
    noData: "No Data :(",
    locationToggle: "Show Location",
    labDataIdToggle: "Show Lab id",
    alert: {
      "new-note": "NOTE",
      "new-photo": "PHOTO",
      "lab-data": "LAB DATA",
      "lab-data-comment": "LAB DATA COMMENT",
      "lab-data-pending": "PENDING LAB DATA",
      "new-task-due": "TASK DUE",
      protocol: "WATCH OUT FOR",
      "new-advice": "ADVICE",
      "new-document": "DOCUMENT",
      "new-sensor-trigger": "SENSOR TRIGGER",
      "new-note-mention": "NOTE MENTION",
    },
    actions: {
      generateQrCodes: "Generate QR codes",
      duplicateCrops: "Duplicate",
    },
    advanced: {
      label: "Advanced",
      title: "Advanced crop filters",
      add: "Add",
      update: "Update",
      execute: "Execute",
      clear: "Clear",
    },
    view: "View",
    cropLabel: "Crop label",
    manageColumns: "Manage columns",
    showHideSortCols: "Show, hide or sort columns",
    selected: "selected",
    substrateInfo: "Substrate info",
    referenceGenus: "Reference genus",
    phase: "Phase",
    drippers: "Drippers",
    spacingDate: "Arrival date of young plant material",
    expectedCultivationTime: "Expected cultivation time",
    description: "Description",
    objectives: "Objectives",
    plantsPerContainer: "Plants per container",
    numberOfContainers: "Number of containers",
    substrateMixture: "Substrate mixture",
    fertilization: "Fertilization",
  },
  cropsQr: {
    qrCode: "QR code",
    urlType: {
      noteForm: "Note form",
      cropLog: "Crop log",
      cropProfile: "Crop profile",
      options: [
        { id: "noteForm", name: "Note form", docLabel: "note form" },
        { id: "cropLog", name: "Crop log", docLabel: "logbook" },
        { id: "cropProfile", name: "Crop profile", docLabel: "Crop profile" },
      ],
    },
    openField: "Open field",
    start: "Start",
    wk: "wk",
    end: "End",
    objectcode: "Objectcode",
    cropLabel: "Crop label",
  },
  cropForm: {
    defectWhere: "Defect in",
    yourNote: "Your note",
    fertilizationBin: "Fertilization tanks",
    document: "Document",
    yield: "Yield",
    plantGrowthHead: "Plant growth: head (thickness)",
    plantGrowthInternodes: "Plant growth: internodes",
    brix: "Brix value",
    addBrix: "Add brix value",
    numberOfRunners: "Number of runners",
    trussFormation: "Truss formation",
    addNumberOfRunners: "Add number of runners",
    numberOfCrowns: "Number of crowns",
    addNumberOfCrowns: "Add number of crowns",
    numberOfFlowers: "Number of flowers",
    addNumberOfFlowers: "Add number of flowers",
    numberOfFruitsPerTruss: "Number of fruits per truss",
    numberOfFruitsPerPlant: "Number of fruits per plant",
    averageFruitWeight: "Average fruit weight",
    fruitDiameter: "Fruit diameter",
    numberOfOpenFlowers: "Flowering: number of open flowers",
    leafLength: "Leaf length",
    nr: "nr",
    chooseActivity: "Choose activity",
    chooseChecklist: "Choose checklist",
    nextChecklistNote: "Next checklist note",
    repeat: "Repeat",
    backChecklistNote: "Back to previous checklist note",
    note: "Note",
    for: "for",
    createANote: "Create a note for your specific cultivation",
    customLabel: "Custom label",
    measurementValue: "Measurement value",
    addProduct: "Add product",
    photoLabel: "Photo label",
    pruning: "Pruning",
    pinching: "Pinching",
    spacing: "Spacing",
    move: "Move",
    sorting: "Sorting",
    changePotsize: "Change container",
    rotate: "Rotate",
    cuttingLeaf: "Cutting leaf",
    layFoil: "Lay foil",
    removeFoil: "Remove foil",
    harvest: "Harvesting",
    lower: "Lower",
    settle: "Secure",
    removeLeafs: "Remove leafs",
    bendIn: "Bend in",
    placeSticks: "Place sticks",
    siftingThrough: "Sifting through",
    pollinate: "Pollinate",
    binding: "Binding",
    weeding: "Weeding",
    deflower: "Deflower",
    removeTendrils: "Remove tendrils",
    placeCatchCards: "Place catch cards",
    tendrils: "Tendrils",
    raiseTheGauze: "Raise the gauze",
    cropMaintenance: "Crop maintenance",
    removeBuds: "Remove buds",
    technicalService: "Technical service",
    PPP: "Plant protection product",
    biocide: "Biocide",
    adjuvant: "Adjuvant",
    liquidCropControl: "Liquid crop control",
    spreadingCropControl: "Spreading crop control",
    growthRegulation: "Growth regulation",
    controlRound: "Control round",
    spreadableFertilizers: "Spreadable fertilizers",
    foliarFertilizers: "Foliar fertilizers",
    scouting: "Scouting",
    misc: "Misc",
    checklist: "Checklist",
    selected: "Selected {nr} crops",
    selectLocation: "Select location",
    selectYourLocation: "Select your location",
    cropsSelected: "Total of {nr} crops selected",
    noActiveCrops: "Currently no crops are active in this location",
    selectCrops: "Select crops",
    selectACrop: "Select a crop",
    crops: "crops",
    locations: "Locations",
    selectedLocations: "Selected locations",
    search: "Search",
    selectAll: "Select all",
    deselectAll: "Deselect all",
    showMore: "Show more",
    stepNo: "Step {cs} of {ms}",
    labData: "Lab data",
    climateData: "Climate data",
    fertilizationBins: "Fertilization tanks",
    advice: "Advice",
    "general-note": "General note",
    fertilizationEc: "EC during watering",
    watering: "Watering",
    ecWatering: "Watering + EC",
    ecWateringDrainage: "Watering + EC + Drainage",
    drainage: "Drainage",
    measurementEC: "EC-measurement",
    measurementpH: "pH measurement",
    EC: "EC",
    "fertilization-Water": "Irrigation",
    amountSpreadable: "Amount",
    concentrationSpreadable: "Concentration",
    "fertilization-fertilization": "Foliar fertilization",
    amountAgent: "Amount",
    choose: "Choose",
    cause: "Cause",
    units: "Units",
    or: "or",
    andOr: "and/or",
    selectedCrop: "Selected crop",
    selectedCrops: "Selected crops",
    change: "Change",
    completed: "completed",
    qrCode: "Scan QR",
    start: "Start",
    save: "Save",
    saveAddNew: "Save + add new",
    ok: "Ok",
    cancel: "Cancel",
    pick: "Pick",
    measurement: "Measurement",
    substrate: "Substrate",
    drain: "Drain",
    gift: "Gift",
    ditch: "Ditch",
    observation: "Observation",
    waterAndFertilization: "Water and fertilization",
    logTask: "Registration of",
    cultivationActivity: "Cultivation activity",
    addPhoto: {
      label: "Add photo",
      overzicht: "Overview",
      enkelePlant: "Single plant",
      detail: "Detail",
      wortels: "Roots",
    },
    photoType: "Photo Type",
    category: "Category",
    whatMeasurement: "Registration of",
    addPH: "Add pH measurement",
    addLength: "Add length in cm",
    addDiameter: "Add diameter in cm",
    numberOfBuds: "Number of buds/flowers",
    weight: "Weight",
    addBuds: "Add number of buds",
    addWeight: "Add weight",
    diameter: "Diameter",
    whatObservation: "Observation:",
    defectPhenomenon: {
      label: "Defect phenomenon",
      options: {
        bladvlekkenGeel: "Leaf spots - yellow",
        bladvlekkenBruin: "Leaf spots - brown",
        bladrandGeel: "Leaf margin - yellow",
        BladrandBruin: "Leaf margin - brown",
        krullendBlad: "Curly leaves",
        bladval: "Leaf drop",
        knopval: "Bud drop",
      },
    },
    leafObservation: "Leaf observation",
    defectPhenomenonIn: "Defect phenomenon in:",
    youngLeaves: "Young leaves",
    oldLeaves: "Old leaves",
    leafBurn: "Leaf Burn",
    flowering: "Flowering",
    fungus: {
      label: "Fungus and bacterial disease",
      options: {
        altenaria: "Altenaria",
        bladvlekkenziekte: "Leaf spot",
        bloemrot: "Flower rot",
        botrytis: "Botrytis",
        didymella: "Didymella",
        fusarium: "Fusarium",
        japanseRoest: "Japanese rust",
        echteMeeldauw: "Powdery Mildew",
        valseMeeldauw: "Downy mildew",
        phytophthora: "Phytophthora",
        pythium: "Pythium (root rot)",
        roetdauw: "Sooty dew",
        sclerotinia: "Sclerotinia",
        stengelbasisrot: "Stem base rot",
        verticillium: "Verticillium",
        zwarteVlekkenziekte: "Black spot disease",
        nofungus: "No fungus and bacterial disease",
      },
    },
    plague: {
      label: "Pest",
      options: {
        aaltjes: "Nematodes",
        bladluizen: "Aphids",
        cicaden: "Cicaden",
        kevers: "Beetles",
        mineervliegen: "Leaf miners",
        muggenVliegen: "Mosquitoes & flies",
        rupsen: "Caterpillars, moths & butterflies",
        mijten: "(Spider) mites",
        trips: "Trips",
        wantsen: "Bugs",
        mole: "Mole",
        otherInsects: "Insects misc",
        mice: "Mice",
        symphyla: "Symphyla",
        weeds: "Weeds",
        slugs: "Slugs",
        springtail: "Springtails",
        whiteFly: "White fly",
        wolluizen: "Mealybugs",
        noplagues: "No pests detected",
      },
    },
    color: "Leaf color",
    failure: "Failure",
    rating: {
      label: "Rating",
      options: {
        germRate: "Germ rate",
        leafColor: "Leaf color",
        leafGloss: "Leaf gloss",
        shootFormation: "Shoot formation",
        flowerSize: "Flower size",
        flowerFirmness: "Flower firmness",
        floridity: "Floridity",
        rooting: "Rooting",
        uniformity: "Uniformity",
        moisture: "Moisture",
        branching: "Branching",
        plantFirmness: "Plant firmness",
        plantStructure: "Plant structure",
        compactness: "Compactness",
        plantQuality: "Plant quality",
        leafQuality: "Leaf quality",
        stemDiameter: "Stem diameter",
        tipSize: "Tip size",
        plantVolume: "Plant volume",
        cutlings: "Cutlings",
        growth: "Growth",
      },
    },
    intensity: {
      label: "Intensity",
      levels: {
        0: "None",
        1: "Light",
        2: "Some",
        3: "Medium",
        4: "A lot",
        5: "Heavy",
      },
    },
    LeafTips: "Leaf tips",
    selectDefect: "Select",
    selectFungi: "Select",
    selectPlague: "Select",
    addEC: "EC during watering",
    addWateringAmount: "Amount/duration of watering",
    addECMeasurement: "Add EC measurement",
    addWaterMeasurement: "Amount of water given",
    whichFertilizationCategory: "Which fertilization category?",
    cropControl: "Crop control",
    whichCropControl: "Which crop control?",
    miscellaneous: "Miscellaneous",
    numberOfMeters: "Number of m²:",
    concentration: "Concentration ml/100 liter:",
    photoUpload: "Photo upload",
    budding: "Pinching",
    widening: "Spacing",
    moveCultivationBatch: "Move cultivation batch",
    cutting: "Cutting",
    potting: "Potting",
    deliverFinishedPlants: "Deliver",
    pottingUnit: "plugs/cuttings",
    deliverFinishedPlantsUnit: "plants",
    floridityUnit: "average amount of flowers",
    sow: "Sow",
    makeCuttings: "Make cuttings",
    cuttingUnder: "Cutting under",
    cuttingRound: "Cutting round",
    length: "Length",
    chemicalProtection: {
      label: "Liquid crop protection",
      options: {
        batavia: "Batavia",
        boxer: "Boxer",
        butisanS: "Butisan S",
        collis: "Collis",
        cecece: "CeCeCe",
        AZ500: "AZ 500",
        abamectineHf: "Abamectine HF",
        centurionplus: "Centurion Plus",
        carex10sc: "Carex 10 SC",
        decis: "Decis",
        dithane: "Dithane NeoTec",
        dimanin: "Dimanin",
        floramite: "Floramite",
        flint: "Flint",
        gnatrolSc: "Gnatrol SC",
        karateZeon: "Karate zeon",
        limocide: "Limocide",
        lunaPrivilige: "Luna Privilege",
        mirageElan: "Mirage Elan",
        miragePlus: "Mirage plus",
        movento: "Movento",
        nissorun: "Nissorun",
        ortiva: "Ortiva",
        ortivaTop: "Ortiva Top",
        paraat: "Paraat",
        previcurEnergy: "Previcur Energy",
        proplant: "Proplant",
        ranman: "Ranman",
        rovral: "Rovral",
        serenade: "Serenade",
        scelta: "Scelta",
        sivantoPrime: "Silvanto Prime",
        siltac: "Siltac",
        signum: "Signum",
        steward: "Steward",
        sumicidinSuper: "Sumicidin Super",
        switch: "Switch",
        teldor: "Teldor",
        teppeki: "Teppeki",
        tracer: "Tracer",
        xentarie: "Xentarie",
        vertimec: "Vertimec",
        azatin: "Azatin",
        biosweet: "Biosweet",
        bitterzout: "Bitterzout",
        contans: "Contans",
        dipel: "Dipel",
        dolokal: "Dolokal",
        kumulusS: "Kumulus S",
        kumar: "Kumar",
        microsulfo: "Spuitzwavel Micro Sulfo",
        nectarMagnwsiumDuo: "Nectar magnesium duo",
        neemazal: "NeemAzal ",
        suitzwavel: "Zwavel",
        trianium: "Trianum",
        conserve: "Conserve",
        aCSzwavel800: "ACS- zwavel 800",
        admiral: "Admiral",
        alibiflora: "Alibi flora",
        altacor: "Altacor",
        applaud: "Applaud",
        asperello: "Asperello",
        attracker: "Attracker",
        bifasto: "Bifasto",
        biosoap: "Biosoap",
        botanigardWP: "Botanigard WP",
        captosan500sc: "Captosan 500 sc",
        closer: "Closer",
        dazideenhance: "Dazide enhance",
        ecomist: "Eco mist",
        ethrelA: "Ethrel A",
        fado: "Fado",
        falgro: "Falgro",
        flipper: "Flipper",
        frupica: "Frupica",
        fungaflash: "Fungaflash",
        gazelle: "Gazelle",
        geoxe: "Geoxe",
        hasten: "Hasten",
        hfOnkruidVrij: "HF Onkruid vrij",
        hiwett: "Hi wett",
        inssimo: "Inssimo",
        karma: "Karma",
        mainspring: "Mainspring",
        MCPA500: "MCPA 500",
        nocturn: "Nocturn",
        nomolt: "Nomolt",
        oberon: "Oberon",
        oroganic: "Oroganic",
        pedimentrookontwikkelaar: "Pediment rookontwikkelaar",
        pirimor: "Pirimor",
        pironpushbox: "Piron pushbox",
        prolong: "Prolong",
        ranmanTop: "Ranman Top",
        rhizopon: "Rhizopon",
        rocket: "Rocket",
        rodilinHavermix: "Rodilin Havermix",
        rodilonsoftblock: "Rodilon soft block",
        ridomilGold: "Ridomil Gold",
        rizolex: "Rizolex",
        runner: "Runner",
        saponin: "Saponin",
        score250EC: "Score250EC",
        silwetgold: "Silwet gold",
        sporgon: "Sporgon",
        sluxx: "Sluxx",
        sonata: "Sonata",
        suikerlokstof: "Suikerlokstof",
        tzwavel: "T- zwavel",
        taegro: "Taegro",
        takumi: "Takumi",
        tomCatRTU: "Tom Cat RTU",
        topaz: "Topaz",
        topsinmutra: "Topsin M utra",
        turexPowder: "Turex Poeder",
        vacciplant: "Vacciplant",
        valiosoSt: "Violoso-ST",
        vivando: "Vivando",
        abir: "Abir",
        mycotal: "Mycotal",
        motto: "Motto",
        meltatox: "Meltatox",
        winner: "Winner",
        helioterpen: "Helioterpen",
        thripsLok: "ThripsLok",
        fulvic: "Fulvic",
      },
    },
    addChemicalProtection: "Select product:",
    addOrganicProtection: "Select product:",
    organicProtection: {
      label: "Spreadable crop control (biological)",
      options: {
        adaliaBipunctata: "Adalia bipunctata",
        amblydromalusLimonicus: "Amblydromalus limonicus",
        AZ500: "AZ 500",
        amblyseiusSwirskii: "Amblyseius swirskii",
        aphelinusAbdomalis: "Aphelinus abdomalis",
        aphidiusColemani: "Aphidius colemani",
        aphidiusErvi: "Aphidius ervi",
        aphidoletesAphidimyza: "Aphidoletes aphidimyza",
        azatin: "Azatin",
        biosweet: "Biosweet",
        bitterzout: "BitterSalt",
        chrysoperlaCarnea: "Chrysoperla carnea",
        contans: "Contans",
        dacnusaSibirica: "Dacnusa sibirica",
        diglyphusIsaea: "Diglyphus isaea",
        dipel: "Dipel",
        dolokal: "Dolokal",
        encarsiaFormosa: "Encarsia formosa",
        eretmocerusEremicus: "Eretmocerus eremicus",
        feltiellaAcarisuga: "Feltiella acarisuga",
        hypoaspisMiles: "Hypoaspis miles",
        kumulusS: "Kumulus S",
        macrochelesRobustulus: "Macrocheles robustulus",
        macrolophusPygameus: "Macrolophus pygameus",
        microsulfo: "Microsulfo",
        nectarMagnwsiumDuo: "Nectar magnesium duo",
        neemazal: "NeemAzal",
        neoseiulusCalifornicus: "Neoseiulus californicus",
        neoseiulusCucumeris: "Neoseiulus cucumeris",
        oriusLaevigatus: "Orius laevigatus",
        phytoseiulusPersimilis: "Phytoseiulus persimilis",
        suitzwavel: "Sulfur",
        springbok: "Springbok",
        steinernemaCarpocapsae: "Steinernema carpocapsae",
        steinernemaFeltiae: "Steinernema feltiae",
        transeiusMontdorensis: "Transeius montdorensis",
        trianium: "Trianum",
        trichogrammaArchaeae: "Trichogramma archaeae",
        anagyrusVladimiri: "Anagyrus vladimiri",
        aphidiusMatricariae: "Aphidius matricariae",
        pekelkreeftcystenArtemiaspp: "Pekelkreeftcysten artemia spp",
        athetaCoriaria: "Atheta coriaria",
        bombusTerrestris: "Bombus terrestris (Bumblebees)",
        cryptolaemusMontrouzieri: "Cryptolaemus montrouzieri",
        delphastusCatalinae: "Delphastus catalinae",
        enermix: "Enermix",
        ephestiaKuehniella: "Ephestia kuehniella",
        carpoglyphusLactis: "Carpoglyphus lactis",
      },
    },
    addgrowthRegulation: "Add organic crop protection",
    addMeasurement: "Add measurement",
    remmen: {
      label: "Growth regulation",
      options: {
        alar: "Alar",
        bonzi: "Bonzi",
        calciumchloride: "Calciumchloride",
        chryzopon: "Chryzopon",
        controlGrowdesign: "Growdesign",
        cycocel: "Cycocel",
        dazide: "Dazide",
        elastoG5: "Elasto G5",
        koper: "Koper",
        magnesiumchloride: "Magnesium chlorid",
        pirouette: "Pirouette",
        regalis: "Regalis",
        rhizoponAa: "Rhizopon AA",
        uplccc750: "Upl ccc 750",
        questuran: "Questuran",
      },
    },
    addRemmen: "Select product:",
    floweringTreatment: {
      label: "Flowering treatment",
      options: {
        auxine: "Auxine",
        bonzi: "Bonzi",
        carousel: "Carousel",
        configureSc: "Configure SC",
        ethylene: "Ethylene",
        florgib: "Florgib",
        gibbereline: "Gibbereline",
        valiosoSt: "Valioso ST",
      },
    },
    addFloweringTreatment: "Select product:",
    addNote: "Add note",
    filters: { title: "Filters", gotoLevel2: "Go to level 2" },
    edit: "Edit",
    reset: "Reset",
    uploadPhoto: "Photo",
    whatCrop: "To which cultivation belongs the note?",
    whatToDo: "What would you like to do?",
    whatTask: "What task did you perform?",
    whichRegulator: "Which growth regulator did you use?",
    whatObserve: "Additional note",
    howMiliter: "How much milliliter?",
    notAdded: "Success! Would you like to add something else?",
    yes: "Yes",
    amDone: "No, I'm done!",
    yourOwn: "Other product name",
    skip: "Skip",
    skipQuestion: "Skip question",
    tankage: {
      label: "Tank size",
      concentration: "Surface in m²",
      tankage: "Tankage in liters",
    },
    productAmount: {
      label: "Product amount",
      concentrationTotal: "Concentration of product",
      concentrationTotalKg: "Concentration product in kg / Ha",
      tankageTotal: "Amount of spraying solution (L)",
      tankageTotalKg: "Amount product (kg)",
      amountAgent: "Amount agent",
      numberOf: "Number of units applied",
      pieces: "Pieces per unit",
      totalApplied: "Total applied biologicals",
      totalAppliedFertilizer: "Total applied fertilizer",
      kgPer: "Kg per unit",
    },
    product: "Product",
    tankageMix: "Product {idx} of {total}",
    back: "Back",
    next: "Next",
    done: "Done",
    fertilization: {
      label: "Fertilization",
      spreadible: {
        label: "Spreadable fertilizers",
        title: "Select product:",
        options: {
          dcmMix1: "DCM mix 1",
          dcmMix2: "DCM mix 2",
          dcmMix3: "DCM mix 3",
          dcmMix4: "DCM mix 4",
          dcmMix5: "DCM mix 5",
          dcmMix6: "DCM mix 6",
          vivikali: "Vivikali",
          dolokal: "Dolokal",
          kalkalmmonsalpeter: "Kalkalmmonsalpeter",
          magnesamonsalpeter: "Magnesamonsalpeter",
          protixflytilizer: "Protix Flytilizer",
          patentkali: "Patentkali",
          innogreenecogroei: "Innogreen ECO groei",
          tripelsuperfosfaat: "Tripelsuperfosfaat",
        },
      },
      foliar: {
        label: "Foliar fertilizers",
        title: "Select product:",
        options: {
          cuprum: "Cuprum",
          calciumchloride: "Calciumchloride",
          salifort: "Salifort",
          siliforce: "Siliforce",
          espotop: "Bitterzout Epso Top",
          bitterzout: "Bitterzout",
          fertigroMSN: "Fertigro MSN",
          fertigroCN: "Fertigro CN",
          zwavelzuur37: "Zwavelzuur 37%",
          superFK: "Super FK",
          universolgroen: "Universol Groen 23-6-10",
          kalksalpeter: "Kalksalpeter",
          kalksalpeterCalcinite: "Kalksalpeter Calcinite",
          kalksalpeterTropicote: "Kalksalpeter Tropicote",
          calsal: "Calsal Substrafeed Yara",
          yaraNitromag: "YaraBela Nitromag 27+4 MgO",
          spuitzwavel: "spuitzwavel",
          yaraSulfan: "YaraBela Sulfan 24+18SO3",
          ureumprills: "Ureum Prills",
          vitalance: "Vitalance",
        },
      },
    },
    causesCropcontrol: {
      options: {
        aaltjes: "Aaltjes",
        altenaria: "Altenaria",
        bacterienov: "bacteria",
        bladvlekkenziekte: "Leaf spot",
        beworteling: "rooting",
        bloemrot: "flower rot",
        botrytis: "Botrytis",
        bladluizen: "alphids",
        bloeibehandeling: "flowering treatment",
        cicaden: "Cicaden",
        echteMeeldauw: "Powdery mildew",
        erwinia: "Erwinia",
        valseMeeldauw: "Downy mildew",
        beetles: "Beetles",
        mineervliegen: "Leafminor",
        muggenVliegen: "Mosquitoes & flies",
        rupsen: "Caterpillars, moths & butterflies",
        mijten: "(Spider) mites",
        trips: "Trips",
        wantsen: "Bugs",
        wolluizen: "Mealybugs",
        didymella: "Didymella",
        fusarium: "Fusarium",
        japanseRoest: "Japanese rust",
        phytophthora: "Phytophthora",
        pythium: "Pythium (root rot)",
        roetdauw: "Sooty dew",
        sclerotinia: "Sclerotinia",
        stengelbasisrot: "Stem base rot",
        verticillium: "Verticillium",
        zwarteVlekkenziekte: "Black spot disease",
        otherBacteria: "other bacteria",
        rooting: "rooting",
        leafSpot: "Leaf spot",
        floweringTreatment: "Flowering treatment",
        centerRot: "Center rot",
        adhesive: "Adhesive",
        otherInsects: "Insects misc",
        tuberRot: "Tuber rot",
        attractant: "Attractant",
        aphids: "aphids",
        mole: "mole",
        mice: "mice",
        shoreFly: "Shore fly",
        weeds: "Weeds",
        disinfection: "Disinfection",
        misk: "Misc",
        plantResistance: "plant resistance",
        rats: "rats",
        remmen: "Growth regulation",
        rhizoctonia: "Rhizoctonia",
        caterpillars: "Cater pillars",
        otherFungus: "Fungus misc",
        slugs: "slugs",
        spiders: "(spider) mites",
        springtail: "springtails",
        sugarRot: "sugar rot",
        whiteFly: "white fly",
        rootRot: "root rot",
        symphyla: "Symphyla",
        tensides: "Tensides",
      },
    },
    backgroundSaveInProgress:
      "Some data is still being saved in the background...",
    pleaseSelectCrops: "Please select crops before proceeding.",
    pleaseWaitbackgroundSave: "Please wait until the data has been saved...",
    optionalFieldsAccordionButtonLabel: "Optional details",
  },
  noteActivity: {
    newNote: "New note",
    userHaseCreated: "has created a new",
    noteFor: "note for",
  },
  noteCard: {
    pieces: "pieces",
    failureUnits: "Failure units",
    before: "Before",
    after: "After",
    saveFor: "Save for",
    crops: "crops",
    favourites: "Favourites",
    editDescription: "Edit description",
    deleteBatch: "Delete batch",
  },
  notesFeed: {
    totalTankage: "Total tankage",
    uploadPhoto: "Upload photo",
    log: "Log",
    fertilizationEc: "EC during watering",
    EC: "EC",
    "fertilization-Water": "Irrigation",
    amountSpreadable: "Amount",
    concentrationSpreadable: "Concentration",
    "fertilization-fertilization": "Spreadable fertilization",
    spreadible: "Spreadible fertilization",
    PPP: "Plant protection product",
    biocide: "Biocide",
    adjuvant: "Adjuvant",
    foliar: "Foliar fertilization",
    amountAgent: "Amount of product",
    labData: "Lab data",
    color: "Leaf color",
    ec: "EC",
    ph: "pH",
    floweringTreatment: "Flowering treatment",
    water: "Duration",
    length: "Length",
    diameter: "Diameter",
    numberOfBuds: "Number of buds",
    weight: "Weight",
    surface: "Surface",
    size: "Tankage",
    chemicalProtection: "Liquid protection",
    organicProtection: "Spreading (biological pest control)",
    remmen: "Growth regulation",
    concentration: "Concentration",
    amount: "Tankage",
    tankage: "Tankage",
    edit: "Edit",
    download: "Download",
    exportPdf: "To PDF",
    exportXlsx: "To Excel",
    compare: "Compare",
    cancelEdit: "Cancel edit",
    toggleView: "Graph view",
    successfullyDeleted: "Notes were deleted successfully",
    remove: "Remove",
    note: "note",
    extraNote: "Extra note",
    notes: "notes",
    leafObservation: "Leaf observation",
    defectPhenomenon: "Leaf margin - chlorotic",
    leafBurn: "Leaf Burn",
    flowering: "Flowering",
    intensity: "Intensity",
    counting: "Counting",
    fungus: "Fungal disease",
    plague: "Pest",
    failure: "Practice failure",
    failureRate: "Failure rate",
    rating: "Rating",
    ratingValue: "Rating",
    LeafTips: "Leaf tips",
    miscellaneous: "Miscellaneous",
    misc: "Miscellaneous",
    pieces: "Pieces per unit",
    numberOf: "Units applied",
    numberOfUnits: "Units applied",
    totalApplied: "Total applied",
    kgPer: "Kg per unit",
    kgPerUnit: "Kg per unit",
    totalIrrigation: "Total irrigation",
    floridity: "Floridity",
    units: {
      pieces: "pieces",
      "pieces / m2": "pieces / m2",
      "ml / 100 liters": "ml / 100 liters",
      "gram / 100 liters": "gram / 100 liters",
      kg: "kg",
      gr: "gram",
    },
    formBranches: [
      { id: "all", label: "Show all" },
      { id: "favorites", label: "Favorites" },
      { id: "cultivationActivity", label: "Cultivation activity" },
      { id: "measurement", label: "Measurement" },
      { id: "observation", label: "Observation" },
      { id: "waterAndFertilization", label: "Water and fertilization" },
      { id: "cropControl", label: "Crop control" },
      { id: "labData", label: "Lab data" },
      { id: "addPhoto", label: "Photos" },
      { id: "misc", label: "Misc" },
    ],
    addPhoto: "Photo",
    addFavorite: "Add to favorites",
    removeFavorite: "Favorite",
    favourite: "Favorite",
    labResults: "Lab results",
    cultivationStart: "Cultivation started",
    cultivationEnd: "Cultivation ended",
    cultivationContinue: "Cultivation continued",
    started: "Cultivation started at",
    substrate: "Substrate",
    drain: "Drain",
    gift: "Gift",
    ditch: "Ditch",
    potSizeChange: "Changed pot size from",
    potSizeChangeTo: "to",
    locationChange: "Changed location from",
    locationChangeTo: "to",
    print: {
      companyName: "Company name",
      label: "Label",
      genus: "Genus",
      species: "Species",
      cultivar: "Cultivar",
      startWeek: "Start week",
      potSize: "Pot size",
      labId: "L&S ID",
      startingAmount: "Starting amount",
      currentAmount: "Current amount",
      businessLocation: "Business location",
      level1Locations: "Level 1 locations",
      level2Locations: "Level 2 locations",
      cropOverview: "Crop overview",
      referenceNumber: "Reference number",
      photoReference: "Photo reference",
      photosOverview: "Photos overview",
      log: "Log",
      summary: "Summary",
      at: "at",
      by: "by",
      batchNumber: "Batch number",
      youngPlantSuppliers: "Young plant suppliers",
      sizeDescription: "Size description",
      supplierDocumentNr: "Supplier document nr.",
      containerNr: "Container number",
      substrateMixture: "Substrate mixture",
      fertilization: "Fertilization",
      barcode: "Barcode",
      weeknumber: "Week number",
      potDate: "Pot date",
      endDate: "End date",
      spacingDate: "Arrival date of young plant material",
      description: "Description",
      objectives: "Objectives and challenges for this crop",
      container: "Container",
      openField: "Open field",
      tray: "Tray",
      plugType: "Plug type",
    },
    week: "Week",
    weather: "Weather",
    selectAll: "Select all",
    deselectAll: "Deselect all",
    clearSelection: "Clear selection",
  },
  sensors: {
    externalId: "external id",
    sensorId: "id",
    editSensor: {
      title: "Edit sensor",
      connectedCrops: {
        title: "Crops",
        add: "add",
        edit: "edit",
        remove: "remove",
        addEdit: "Add/edit sensor connected crop",
        crop: "Crop",
        startEndDate: "Select start / end date",
        noStartDate: "No start date",
        noEndDate: "No end date",
      },
      dataYAxisBounds: {
        label: "Data y axis bounds",
        options: {
          default: "Default bounds",
          boundByValues: "Bound by measurements",
        },
      },
      highTemperature: "High temperature",
      lowTemperature: "Low temperature",
      highSoilMoisture: "High soil moisture",
      lowSoilMoisture: "Low soil moisture",
      receiveAlerts: "Receive alerts",
      button: "Save",
    },
    timeRange: {
      title: "Time range",
      placeholder: "Select range",
      options: {
        lastDay: "Last day",
        lastWeek: "Last week",
        lastMonth: "Last month",
        lastYear: "Last year",
      },
    },
    sensorName: "Sensor name",
    action: "Action",
    yourSensorsData: "Your sensors data",
    aggView: "An aggregated view of all your connected data",
    settings: "Settings",
    selectSensors: "Select sensors",
    pickMetrics: "Pick metrics",
    backToAll: "Back to all",
    noSensorData: "No sensor data for this time range",
    pleasePickSensors: "Please pick sensors and metrics to display data",
    config: "Sensor settings",
    allSensors: "All sensors",
    sensors: "sensors",
    noSensors: "No sensors",
  },
  profile: {
    name: "Name",
    misc: { companyLogo: { title: "Company logo" } },
    contact: {
      companyName: "Company name",
      contactPerson: "Contact person",
      phoneNumber: "Telephone number",
    },
    password: "Password",
    confirmPassword: "Confirm password",
    chooseHowToGetNotifications: "Choose how to get your notifications",
    chooseWhatMessagesToGet: "Choose what messages to get",
    chooseYourPreferredLanguage: "Choose your preferred language",
    settings: {
      notificationSettings: {
        sensorAlerts: "Sensor alerts",
        taskAlerts: "Task alerts",
        dailySummaryEmails: "Daily summary emails",
        endOfCultivationAlertsEmails: "End of cultivation alerts emails",
      },
    },
    cropFields: { title: "Optional crop fields" },
    youngPlantSuppliers: { title: "Young plant suppliers" },
    sensorProvider: "Sensor provider",
    pdfFields: { title: "Optional pdf fields" },
    cropLabels: {
      fields: "Select QR crop labels fields",
      sizes: {
        select: "Select QR crop labels size",
        large: "Large",
        small: "Small",
      },
    },
    substrateMixtures: { title: "Substrate mixture codes" },
    noteLabels: { title: "Select custom note labels in note form" },
    photoLabels: { title: "Select custom photo labels in note form" },
    wateringUnits: { title: "Select default watering unit in note form" },
    drainageUnits: { title: "Select default drainage unit in note form" },
    cultivationActivities: {
      title: "Select cultivation activities in note form",
    },
    measurementTypes: { title: "Select measurement types in note form" },
    rating: { title: "Select rating items in note form" },
    fillSurfaceForNewCrop: {
      title: "Use full surface area defined by location when adding a new crop",
    },
    cropFullSurfaceAreaForTasks: {
      title: `Use full surface area defined by location for old crops without surface area specified.
        (Useful when you have one crop per location and the option above will take too much effort to change the surface area for each crop)
        `,
    },
    wateringItems: {
      title: "Fields included in note type Watering + EC + Drainage",
    },
  },
  locationsQr: {
    cropsAtLocations: "Note by crops at location",
    notitie: "Note",
    foto: "photo",
    title: "Locations QR code",
  },
  overview: { title: "List of Growers", searchGrower: "Search grower" },
  admin: {
    title: "Admin",
    companyName: "Name",
    contactPerson: "Contact person",
    addCompany: "Add company",
    addThirdPartyUsers: "Add third party users",
    account: "Account",
    yourCompanyAccountDetails: "Your company account details.",
    yourPersonalAccount: "Your personal account.",
    locationsConfig: { title: "Locations" },
    adminOwner: "Admin owner",
    advisorConfig: {
      title: "Advisor Config",
      addAdvisor: "Add advisor",
      updateAdvisor: "Update advisor",
      deleteAdvisor: "Delete advisor",
      cancel: "Cancel",
      editAction: "Edit",
      thSlug: "Name",
      thCompanies: "Companies",
      thAction: "Action",
      fieldSlug: "Slug",
      fieldCompanyIDs: "Company IDs",
      thirdPartyConfig: "Third party config",
    },
    roleConfig: {
      title: "Role Config",
      addRole: "Add role",
      editRole: "Edit role",
      rolesTable: {
        title: "Roles",
        columns: ["Role", "Actions"],
      },
      roleName: "Role name",
      permissionsTable: {
        columns: ["Action", "View", "Create", "Edit", "Delete"],
      },
      allowedLocations: {
        title: "Allowed locations",
        label: "Select allowed locations",
      },
      forAllCompanies: "Display for all companies",
      forThirdParty: "Intended for third parties",
      forThirdPartyDefault: "Assign by default to third parties",
      save: "Save",
      cancel: "Cancel",
    },
    adminOwnerConfig: {
      title: "Admin Owner Config",
      selectCompany: "Select company",
      selectUser: "Select user",
      save: "Save",
    },
    userRoleConfig: {
      title: "User Role Config",
      usersTable: {
        title: "Users",
        columns: ["Name", "Role", "Actions"],
      },
      save: "Save",
    },
    sensorConfig: {
      title: "Sensor Config",
      selectCompany: "Select company",
      save: "Save",
    },
    companyLabels: "Company properties",
    cta: "Add company",
  },
  signIn: {
    signInToYourAccount: "Sign in to your account",
    form: {
      emailAddress: {
        text: "Email address",
        errors: {
          userEmailNotFound: "No account found with this e-mail address.",
          invalidEmailAddress: "Invalid e-mail address.",
          required: "E-mail address is required.",
        },
      },
      password: {
        text: "Password",
        error: "Incorrect password.",
        required: "Password is required.",
      },
      button: "Sign In",
      linkText: "Forgot your password?",
    },
  },
  signUp: {
    title: "Sign up",
    errors: {
      emailInUse: "Email address already in use.",
      passwordWeak: "Password should be at least 6 characters",
      passwordMismatch: "Passwords must match",
    },
  },
  resetPassword: {
    title: "Password reset",
    message:
      "Enter your user account's email address and we will send you a password reset link.",
    placeholder: "Enter your email address",
    send: "Send password reset email",
    successMessage: "A link was sent!",
    emailNotUsed: "This email is not used!",
    updateSuccessMessage: "Password Updated!",
  },
  climateData: {
    title: "Climate data",
    paramTypes: [
      {
        id: "set",
        name: "Set",
      },
      {
        id: "achieved",
        name: "Achieved",
      },
    ],
    categories: {
      indoor: "Climate computer",
      outdoor: "Weather",
    },
    options: {
      parameters: "Parameters",
      timeframes: "Timeframes",
      units: "Units",
    },
    parameters: {
      temperature: "Temperature",
      temperatureMax: "Temperature max",
      temperatureMin: "Temperature min",
      light: "Light",
      airh: "Air humidity",
      co2: "CO2",
      radiation: "Radiation",
      "ventilation-a": "Ventilation A",
      "ventilation-b": "Ventilation B",
      shading: "Shading",
      substrate_temperature: "Substrate temperature",
    },
    timeframes: {
      "24h": "24h",
      day: "day",
      night: "night",
      agendaWeekNr: "week number",
      "24h-avg": "24h avg",
      week: "week",
    },
    units: {
      c: "℃",
      f: "f",
      "j/cm2-som": "j/cm2-som",
      "mol/m2": "mol/m2",
      "%": "%",
      av: "av",
      vd: "vd",
      ppm: "ppm",
      "wind m/s": "wind m/s",
      radiation: "radiation",
      airh: "airh",
      "w/m2": "w/m2",
      "umol/m2/s": "umol/m2/s",
      daylength: "daylength",
      h: "h",
      "kg/ha": "kg/ha",
    },
    wizard: {
      title: "Register new",
      manage: "Manage entries",
      subtitle:
        "Manage and analyze climate data alongside your cultivation progress",
      selectLocations: "Select locations",
    },
    settings: {
      title: "Settings",
      manage: "Manage settings",
      settingsUpdated: "Settings have been updated",
    },
    week: "Week",
    save: "Save",
    getStarted: "Create a new climate data settings object to get started",
    createSettings: "Create",
  },
  cropActions: {
    duplicateCrops: {
      title: "Duplicate crops",
      clickOnRow: "Click on a row to edit the crop info.",
      proceed: "Proceed",
    },
  },
  dataExplorer: {
    title: "Data Explorer",
    aggView: "An aggregated view of all your connected data",
    graphs: {
      measurements: "Measurements",
      sensors: "Sensors",
      conditions: "Climate & Weather",
      climate: "Climate",
      weather: "Weather",
      activities: "Activities",
    },
    photoExplorer: {
      selectFilters: "Select filters",
      noPhotos: "There are no photos.",
      photoSizeOptions: {
        smallSquare: "Small (square)",
        medium: "Medium",
        large: "Large",
      },
      photoSizeOptionsPlaceholder: "Photo size",
    },
    allMeasurements: "All measurements",
    measurements: "measurements",
    noMeasurements: "No measurements",
    allSensors: "All sensors",
    noSensors: "No sensors",
    allConditions: "All conditions",
    noConditions: "No conditions",
    allActivities: "All activities",
    activities: "activities",
    noActivities: "No activities",
    cropPhotosComparison: "Crop photos comparison",
    sort: {
      none: "Default sorting",
      agenda: "Sort by agenda week",
      cultivation: "Sort by cultivation week",
    },
    labData: {
      labEc: "Lab EC",
      labPh: "Lab pH",
    },
    conditions: {
      title: "conditions",
      parameters: {
        moisture: "Moisture",
        radiation: "Radiation",
        rv: "RV",
        humidity: "Humidity",
        irradiance: "Sum of irradiance",
        leaf: "Sum of hours of wet leaf",
        rain: "Sum of total precipitation",
      },
    },
    sensors: {
      title: "sensors",
      parameters: {
        Moist: "Moisture",
        temp: "Temperature",
        light: "Light",
        battery: "Battery",
      },
    },
    settings: {
      maintainSelections:
        "Maintain filter selections when options become unavailable",
      chooseChipLabel: {
        title: "Comparing crops with the same name",
        subtitle: "Choose one chip label to display differences such as date or location",
        label: "Choose chip label",
      }
    },
    tabs: {
      data: "Data",
      photo: "Photo explorer",
    },
    averages: {
      title: "Averages",
      "30minutes": "30 minutes",
      "1hour": "1 hour",
      "3hours": "3 hours",
      "6hours": "6 hours",
      "12hours": "12 hours",
    },
    timeframes: {
      title: "Timeframes",
      date: "Date",
      pot: "Potting week",
      cal: "Calendar week",
    },
    customActivityTitle: {
      wateringMin: "Watering min",
      wateringCcm2: "Watering CC / m2",
      wateringLm2: "Watering L / m2",
      wateringCcdripper: "Watering CC / dripper",
    },
  },
  docs: {
    title: "Documents",
    newDocument: "New document",
    create: "Create",
    edit: "Edit",
    companyDocument: "company document",
    companyDocuments: "Company documents",
    platformDocument: "platform document",
    platformDocuments: "Platform documents",
    cropDocument: "crop document",
    cropDocuments: "Crop documents",
    addLinkHere: "Add link here",
    shareWith: "Share with",
    shareWithAllCompanies: "Share with all companies",
    file: "File",
    link: "Link",
    allEn: "All English companies",
    allDe: "All German companies",
    allNl: "All Dutch companies",
    allFr: "All French companies",
    documentPreview: "Document preview",
    relatedToLocations: "Related to locations",
    includeInCrops: "Include this information in the crops",
  },
  sensorConfig: {
    providers: {
      blockbax: "Blockbax",
      tentacles: "Tentacles",
    },
  },
  general: { sidebar: { labAnalyses: "Lab analyses", notes: "Notes" } },
  companyProfile: {
    companyId: "Company ID",
    labCustNumber: "Lab customer number",
    invite: "Invite users",
    inviteType: "Invitation type",
    inviteTypes: {
      user: "User",
      admin: "Admin",
    },
    inviteEmailType: "Invitation email type",
    inviteEmailTypes: {
      newCustomer: "New customer",
      substrateSampleService: "Substrate sample service",
      cropServiceLabAnalyses: "Crop service, lab analyses",
    },
    invitationLink: "Invitation link",
  },
  settings: {
    company: {
      title: "Company settings",
      fertilizationMethod: {
        title: "Fertilisation strategy",
        placeholder: "e.g. controlled release fertiliser or 'A-B basin'",
      },
      waterSource: {
        title: "Water source",
        placeholder: "e.g. 'bore hole water' or 'rain water'",
      },
      irrigationMethod: {
        title: "Irrigation method",
        placeholder: "e.g. 'ebb and flow' or 'drippers'",
      },
      typeOfCultivation: {
        title: "Type of cultivation",
        options: {
          containerField: "Container floor space (outdoor)",
          greenhouse: "Greenhouse",
          openSoil: "Open field",
          polytunnel: "Plastic tunnel",
          other: "Others",
        },
      },
      cultivationIndoorOrOutdoor: { title: "Cultivation indoor or outdoor" },
      notes: {
        title: "Additional notes on growing conditions",
        placeholder: "What is relevant for advisers to know?",
      },
      challenges: {
        title: "Challenges/attention points",
        placeholder: "e.g. 'biological control' or 'reduce water usage'",
      },
      certifications: { title: "Certifications", placeholder: "E.g. MPS A+" },
      bulbGrower: {
        title: "Is it your company a Bulb Grower?",
        options: { yes: "Yes", no: "No" },
      },
    },
    notes: { title: "Note settings" },
    crops: { title: "Create crops settings" },
    export: {
      title: "Export settings",
      pdfWeatherOff: "Weather data",
      pdfWeekOff: "Cultivation week + agenda week",
    },
  },
  pdfmake: { downloadPdf: "Download", title: "Generate PDF" },
  noteChecklists: {
    title: "Note checklists",
    add: "Add checklist",
    name: "Checklist name",
    steps: "Steps",
    customLabel: "Custom label",
    details: "Checklist details",
    displayIn: "Display in",
    selectAction: "Select an action",
    action: "Action",
    addAction: "Add action",
  },
  generalSubstrates: {
    substrates: "Substrates",
    addSubstrate: "Add substrate",
    preview: "Preview",
    new: "New substrate",
    insertId: "Insert the substrate id",
    notFound: "Substrate not found",
  },
  substrate: {
    field: {
      targetPh: "Target pH",
      targetEc: "Target EC",
      percentages: "Percentages (peat) alternatives",
      plannedQuantity: "Planned quantity",
      moistureLevel: "Moisture level",
      appliedTo: "Applied to",
    },
  },
  userManagement: {
    user: {
      email: "Email",
      role: "Role",
      status: "Status",
      permissions: "Permissions",
      active: "Active",
      inactive: "Inactive",
      userConfirmation: "User confirmation",
      pendingRequest: "Pending request",
      awaitingRequest: "Awaiting request",
      title: "User",
    },
    thirdParty: {
      accept: "Accept",
      reject: "Reject",
      title: "Third party users and requests",
      demote: "Demote",
    },
    title: "User management",
    admin: { title: "Admin" },
    editPermissions: "Edit permissions",
    advisors: "Advisor users",
    inviteUser: {
      title: "Create account",
      fields: {
        email: "Email address",
        prefLang: "Preferred language",
        invitationType: "Invitation type",
        selectRole: "Select user role",
      },
      submit: "Submit",
      inviteUrl: "Invitation link",
    },
    previewingRole: "Previewing role",
    forAllCompanies: "for all companies",
  },
  companyLabels: {
    consumptionCrops: {
      title: "Consumption Crops",
      options: {
        largeFruit: "Large Fruit",
        smallFruit: "Small Fruit",
        leafVegetables: "Leaf vegetables",
        legumes: "Legumes",
        fruitVegetables: "Fruit vegetables",
        cabbageCrops: "Cabbage crops",
        rootAndTuberVegetables: "Root and Tuber Vegetables",
        stalkVegetables: "Stalk vegetables",
        herbs: "Herbs",
        edibleMushrooms: "Edible mushrooms",
      },
    },
    ornamentals: {
      title: "Ornamentals",
      options: {
        flowerBulbsTubers: "Flower bulbs and flower tubers",
        bulbFlowersTubers: "Bulb flowers and tubers",
        pottedPlants: "Potted plants",
        cutFlowers: "Cut flowers",
        draftShrubs: "Draft shrubs",
        cutGreen: "Cut green",
        beddingPlants: "Bedding plants",
        treeNursery: "Tree nursery",
        growingPerennials: "Growing perennials",
        flowerSeedCultivation: "Flower seed cultivation",
        swampWaterPlants: "Swamp and water plants",
        breedingBasicSeedProduction: "Breeding and basic seed production",
        temporarilyUncultivatedTerrain: "Temporary uncultivated terrain",
        permanentlyUncultivatedTerrain: "Permanently uncultivated terrain",
      },
    },
    cultivationMethod: {
      title: "Cultivation method",
      options: {
        biological: "Biological",
        regular: "Regular",
        openField: "Open field",
        substrateBased: "Substrate based",
        hydroponic: "Hydroponic",
        verticalFarming: "Vertical Farming",
      },
    },
    countries: {
      title: "Countries",
      options: {
        nl: "The Netherlands",
        be: "Belgium",
        de: "Germany",
        uk: "United Kingdom",
        fr: "France",
        na: "North America",
        sa: "South America",
        as: "Asia",
        es: "Spain",
        pl: "Poland",
        ch: "Switzerland",
        dk: "Denmark",
        pt: "Portugal",
      },
    },
  },
  warnings: {
    cameraPermissions: {
      title: "Camera permissions",
      message:
        "You need to enable camera permissions for this site in order to scan qr codes.",
    },
    cameraNotSupported: {
      title: "Device or browser not supported",
      message:
        "Your device or browser does not support camera scanning. Please contact Log & Solve support.",
    },
    slowPhotoUpload: {
      title: "Slow upload",
      message:
        "The photo upload is taking longer than expected. Try to set lower photo resolution in camera app or use a faster network.",
    },
  },
  crud: {
    create: {
      protocol: `Protocol created successfully`,
      notes: `Note(s) created successfully`,
      crop: `Crop(s) created successfully`,
      tankmix: `Tank mix created successfully`,
      advice: `Advice created successfully`,
      document: `Document created successfully`,
      task: `Task created successfully`,
    },
    update: {
      protocol: `Protocol updated successfully`,
      alerts: `Alert(s) updated successfully`,
      crop: `Crop updated successfully`,
      notes: `Note(s) updated successfully`,
      tankmix: `Tank mix updated successfully`,
      advice: `Advice updated successfully`,
      document: `Document updated successfully`,
      company: `Company updated successfully`,
      task: `Task updated successfully`,
    },
    reset: {
      auth: `Password reset link sent successfully`,
    },
    apply: {
      protocol: `Protocol applied successfully`,
    },
    complete: {
      task: `Task(s) completed successfully`,
    },
    delete: {
      task: `Task(s) removed successfully`,
      notes: `Note(s) removed successfully`,
      tankmix: `Tank mix removed successfully`,
      advice: `Advice removed successfully`,
      document: `Document removed successfully`,
      protocol: `Protocol removed successfully`,
    },
    context: {
      protocol: "protocol",
    },
    upload: {
      file: "File uploaded successfully",
    },
    success: "Success",
  },
  errors: {
    fileUpload: "File upload failed",
    someFieldsAreFilledIncorrectly: "Some fields are filled incorrectly",
    completeTask: "Error happened while completing task",
    weDidNotManageTo: `We did not manage to {type} the {context}.`,
    helpUsImprove:
      "Help us improve your experience by sending an error report.",
    somethingWentWrong: "Oops! Something went wrong!",
    type: {
      update: "update",
      create: "create",
      delete: "delete",
      read: "read",
      download: "download",
      import: "import",
      upload: "upload",
      validation: "validate",
      unhandled: "handle",
    },
    context: {
      crop: "crop",
      user: "user",
      note: "note",
      alerts: "alerts",
      company: "company",
      unknown: "unknown",
      file: "file",
      sensor: "sensor",
      document: "document",
      advice: "advice",
      protocol: "protocol",
      tankmix: "tank mix",
    },
  },
  selectCropSettings: {
    cropOptionCard: "Crop option card",
    firstRow: "First row",
    secondRow: "Second row",
    thirdRow: "Third row",
    availableOptions: "Available options",
    draggingTutorial: "Drag and drop chips between rows and available options",
    sortBy: "Sort by",
    direction: "Direction",
    ascending: "Ascending",
    descending: "Descending",
  },
  eoc: {
    title: "End of cultivation",
    updateStatus: "Please update the status of the cultivation",
    extendByWeeks: "Extend by weeks",
    endDate: "End date",
    options: {
      keep: "Keep expected cultivation end date",
      extend: "Extend cultivation period",
      end: "End cultivation",
    },
    update: "Update",
  },
  router: {
    login: "Login",
    passwordReset: "Password reset",
    signUp: "Sign up",
    locales: "Locales",
    switchCompany: "Switch company",
    cropsOverview: "Crops overview",
    dashboard: "Dashboard",
    summary: "Summary",
    sensors: "Sensors",
    lab: "Lab analyses",
    notesOverview: "Notes overview",
    tasksOverview: "Tasks overview",
    addTask: "Add task",
    editTask: "Edit task",
    protocolsOverview: "Protocols overview",
    addProtocol: "Add protocol",
    editProtocol: "Edit protocol",
    duplicateProtocol: "Duplicate protocol",
    advicesOverview: "Advices overview",
    companyDocuments: "Company documents",
    platformDocuments: "Platform documents",
    cropDocuments: "Crop documents",
    dataExplorer: "Data explorer",
    climateOverview: "Climate overview",
    addCrop: "Add crop",
    editCrop: "Edit crop",
    duplicateCrop: "Duplicate crop",
    addNote: "Add note",
    companySettings: "Company settings",
    cropsSettings: "Crops settings",
    exportsettings: "Export settings",
    substratesSettings: "Substrates settings",
    sensorSettings: "Sensor settings",
    locationSettings: "Location settings",
    userManagementSettings: "User management settings",
    notesSettings: "Notes settings",
    protocolsSettings: "Protocols settings",
    tankMixesSettings: "Tank mixes settings",
    fertilizationBinsSettings: "Fertilization bins settings",
    climateDataSettings: "Climate data settings",
    climateBoxesSettings: "Climate boxes settings",
    importCultivationData: "Import cultivation data",
    exportCultivationData: "Export cultivation data",
    companyProfile: "Company profile",
    userProfile: "User profile",
    companiesSettings: "Companies settings",
    advisorsSettings: "Advisors settings",
    rolesSettings: "Roles settings",
    unauthorized: "Unauthorized",
    agreement: "Agreement",
  },
};
